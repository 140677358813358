/* @flow */

import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import BpmnModeler from 'bpmn-js/lib/Modeler';
import BpmnViewer from 'bpmn-js/lib/Viewer';
import uuidv1 from 'uuid/v1';
import styled from 'styled-components';
import { muiTheme } from 'app/themes/materialUi';
import minimapModule from 'diagram-js-minimap';
import { connect } from 'react-redux';
import { DOMParser } from '@xmldom/xmldom';
import { Typography, Grid, IconButton, Tooltip } from '@mic3/platform-ui';
import BpmnColorPickerModule from 'bpmn-js-color-picker';

import Alert from 'app/components/molecules/Alert/Alert';
import FormGenerator from 'app/containers/Designer/Form/components/FormGenerator';
import Layout from 'app/components/Designer/Layout';
import DiagramWrapper, { getDiagramStyles } from 'app/components/molecules/Diagram/DiagramWrapper';
import Icon from 'app/components/atoms/Icon/Icon';
import { bind, memoize, debounce } from 'app/utils/decorators/decoratorUtils';
import { get } from 'app/utils/lo/lo';
import { isElementCreated, getElementSettingPanelComponents, getElementSettingValues, buildEventType } from 'app/utils/designer/process/settings/processElementSettingsUtils';
import { prettifyXml, removeSpaces, parseDomElement } from 'app/utils/designer/process/processDesignerUtils';
import { setTabActions } from 'store/actions/app/appActions';
import { updateProcessId, parseIdFromString } from 'app/components/Designer/ProcessDefinitionId';
import { buildSidebar, closeSidebar } from 'store/actions/sidebar/sidebarActions';
import 'bpmn-js/dist/assets/diagram-js.css';
import 'bpmn-js/dist/assets/bpmn-font/css/bpmn-embedded.css';
import 'bpmn-js-color-picker/colors/color-picker.css';
import 'diagram-js-minimap/assets/diagram-js-minimap.css';

const xmlTemplate = `
<?xml version="1.0" encoding="UTF-8"?>
<definitions
 xmlns="http://www.omg.org/spec/BPMN/20100524/MODEL"
 xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"
 xmlns:xsd="http://www.w3.org/2001/XMLSchema"
 xmlns:flowable="http://flowable.org/bpmn"
 xmlns:affectli="https://affectli.com/bpmn"
 xmlns:activiti="http://activiti.org/bpmn"
 xmlns:bpmndi="http://www.omg.org/spec/BPMN/20100524/DI"
 xmlns:omgdc="http://www.omg.org/spec/DD/20100524/DC"
 xmlns:omgdi="http://www.omg.org/spec/DD/20100524/DI"
 typeLanguage="http://www.w3.org/2001/XMLSchema"
 expressionLanguage="http://www.w3.org/1999/XPath"
 targetNamespace="http://www.flowable.org/processdef"
>
  <process id="templateKey" name="template" isExecutable="true">
    <startEvent id="startEvent1" flowable:formFieldValidation="true"></startEvent>
  </process>
  <bpmndi:BPMNDiagram id="BPMNDiagram_templateKey">
    <bpmndi:BPMNPlane bpmnElement="templateKey" id="BPMNPlane_templateKey">
      <bpmndi:BPMNShape bpmnElement="startEvent1" id="BPMNShape_startEvent1">
        <omgdc:Bounds height="30.0" width="30.0" x="100.0" y="163.0"></omgdc:Bounds>
      </bpmndi:BPMNShape>
    </bpmndi:BPMNPlane>
  </bpmndi:BPMNDiagram>
</definitions>
`;

const DiagramContainer = styled.div`
width: 100%;
height: 100%;

& a:link {
  text-decoration: none;
}

& .content,
& .content > div,
.canvas {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

& .content.with-diagram .canvas {
  visibility: visible;
}

& .canvas .djs-hit.djs-hit-stroke, 
& .layer-overlays {
    visibility: hidden;
}
`;

export const modelerProps = {
    container: '#js-canvas',
    keyboard: {
        bindTo: document
    },
    additionalModules: [
        minimapModule,
        BpmnColorPickerModule,
    ],
    ...getDiagramStyles(),
};

const GridStyled = styled(Grid)`
    height: 100%;
`;

class ProcessDesignerDiagram extends PureComponent<Object, Object> {

    static propTypes = {
        process: PropTypes.object.isRequired,
        saveEditorState: PropTypes.func,
        errors: PropTypes.object,
    };

    propertiesRef: Object = React.createRef();
    modeler: Object;
    state = { selectedElement: null, selectedModelElement: null, errors: false, warnings: [] }

    componentDidUpdate(prevProps) {
        const { process, isPreviewVersion } = this.props;
        const draftVersion = get(this.props, 'draftedDetails.primary.version');
        const prevDraftVersion = get(prevProps, 'draftedDetails.primary.version');
        if(prevProps.process.id !== process.id || prevProps.isPreviewVersion !== isPreviewVersion || draftVersion !== prevDraftVersion
        ) {
            this.buildViewer(this.props.process);
        }
    }

    componentDidMount(){
        const { process } = this.props;
        this.buildViewer(process);
    }

    @bind
    handleCloseSidebar(){
        this.setState({ selectedElement: null, selectedModelElement: null }, this.props.closeSidebar);
    }

    @bind
    @memoize()
    async buildViewer(process) {
        const { isPreviewVersion } = this.props;

        const canvas = document.getElementById('js-canvas');
        canvas.innerHTML = '';

        // if (!this.modeler) {
        this.modeler = isPreviewVersion 
            ? new BpmnViewer({ container: modelerProps.container, ...getDiagramStyles() })
            : new BpmnModeler(modelerProps);
        // }

        await this.modeler.clear();
        let xml = get(this.props.process, 'primary.definition');
        if (!xml) {
            xml = updateProcessId(xmlTemplate, 'templateKey', parseIdFromString(process.name).replace(/^[0-9]+/g, '') || uuidv1().replace(/^[0-9]+|[-]+/g, '').slice(0,8));
            const doc = new DOMParser().parseFromString(xml);
            const processElmnt =  doc.getElementsByTagName('process')[0] || doc.getElementsByTagName('bpmn:process')[0];
            processElmnt.setAttribute('name', process.name);
            xml = String(doc);
            this.props.saveEditorState({ primary: { definition: xml }});
        }
        try {
            const { warnings } = await this.modeler.importXML(xml);

            const { isPreviewVersion } = this.props;
            if(isPreviewVersion) {
            }

            this.setState({ warnings });
        } catch(err) {
            const { warnings, message } = err;
            this.setState({ warnings: [{ message }] });
            console.error('something went wrong:', warnings, message); //eslint-disable-line no-console
        }
        const eventBus = this.modeler.get('eventBus');
        eventBus.on('element.click', 1, this.onElementClick);
        eventBus.on('shape.remove', 5, this.onElementRemoved);
        eventBus.on('connection.remove', 5, this.onElementRemoved);
        eventBus.on('selection.changed', 2, this.onElementClick);
        eventBus.on('element.changed', 6, this.onElementChanged);
        eventBus.on('commandStack.changed', 4, this.onChangeDiagram);

    }

    @bind
    async updateGroupName(data, { name, value }) {
        const { process } = this.props;
        const { selectedElement } = this.state;
        const doc = new DOMParser().parseFromString(process.primary.definition);

        const categoryValueId = selectedElement.getAttribute('categoryValueRef');
        const categoryElement = doc.getElementById(categoryValueId);
        if(categoryElement) {
            categoryElement.setAttribute('value', value || '');
            return prettifyXml(String(doc));
        }

        return prettifyXml(String(doc));
    }

    @bind
    async updateTextAnnotationName(data, { name, value }) {
        const { process } = this.props;
        const { selectedElement } = this.state;
        const doc = new DOMParser().parseFromString(process.primary.definition);

        const elementId = selectedElement.getAttribute('id');
        const annotationElement = doc.getElementById(elementId);

        const textEmnts = annotationElement.getElementsByTagName('text');
        Array.from(textEmnts).forEach(elmn => {
            annotationElement.removeChild(elmn);
        });

        if(value) {
            const textElmntString = `<text>${value}</text>`;
            const newTextElmnt = new DOMParser().parseFromString(textElmntString);
            annotationElement.appendChild(newTextElmnt);    
        }

        return prettifyXml(String(doc));
    }

    @bind
    async updateMultiinstanceProps(data, { name, value }) {
        const { process } = this.props;
        const { selectedModelElement } = this.state;
        const doc = new DOMParser().parseFromString(process.primary.definition);
        const parenElement =  doc.getElementById(selectedModelElement.id);
        const multiInstance = parenElement.getElementsByTagName('multiInstanceLoopCharacteristics')[0];

        if (multiInstance) {
            parenElement.removeChild(multiInstance);
        }

        if (data.multiinstance.multiInstanceType === 'none') {
            return String(doc);
        }

        const newMultiInstance = `
        <multiInstanceLoopCharacteristics
            flowable:isSequential="${data.multiinstance.multiInstanceType}"
            ${data.multiinstance.collection ? `flowable:collection="${data.multiinstance.collection}"` : ''}
            ${data.multiinstance.elementVariable ? `flowable:elementVariable="${data.multiinstance.elementVariable}"` : ''}
          >
          ${data.multiinstance.loopCardinality ? `<loopCardinality>${data.multiinstance.loopCardinality}</loopCardinality>` : ''}
          ${data.multiinstance.completionCondition ? `<completionCondition>${data.multiinstance.completionCondition}</completionCondition>` : ''}
        </multiInstanceLoopCharacteristics>`;

        const newMultiInstanceElmnt = new DOMParser().parseFromString(newMultiInstance);

        const script = parenElement.getElementsByTagName('script')[0];
        if(script) {
            parenElement.insertBefore(newMultiInstanceElmnt, script);
        } else {
            parenElement.appendChild(newMultiInstanceElmnt);
        }

        return prettifyXml(String(doc));
    }

    @bind
    updateScriptProps(data, { name, value }) {
        const { process } = this.props;
        const { selectedElement } = this.state;
        const elementId = selectedElement.getAttribute('id');
        const isScriptTask = selectedElement.nodeName === 'scriptTask';
        let doc = new DOMParser().parseFromString(process.primary.definition);
        let parentElement =  doc.getElementById(elementId);
        
        // normalizing type of task
        if (value.type === 'bpmn' && !isScriptTask) {
            const newScriptTask = String(parentElement)
                .replaceAll('serviceTask', 'scriptTask')
                .replaceAll('flowable:delegateExpression="${gqlScriptServiceTask}"', '');

            const processElmnt = doc.getElementsByTagName('process')[0];
            processElmnt.replaceChild(
                new DOMParser().parseFromString(newScriptTask)?.childNodes[0], 
                parentElement
            );
            
            doc = new DOMParser().parseFromString(String(doc));
            parentElement =  doc.getElementById(elementId);
        } else if (value.type === 'nodeJs' && isScriptTask) {
            const newScriptTask = String(parentElement)
                .replaceAll('scriptTask', 'serviceTask')
                .replaceAll('scriptFormat="javascript"', '');
 
            const processElmnt = doc.getElementsByTagName('process')[0];
            
            processElmnt.replaceChild(
                new DOMParser().parseFromString(newScriptTask)?.childNodes[0], 
                parentElement
            );
            
            doc = new DOMParser().parseFromString(String(doc));
            parentElement =  doc.getElementById(elementId);
        }

        const script = parentElement.getElementsByTagName('script')[0];
        let extensionElements = null;
        Array.from(parentElement.childNodes).forEach((child) => {
            if(child.localName === 'extensionElements') {
                extensionElements = child;
            }
        });

        // removing child if it's nodejs field
        if(extensionElements) {
            Array.from(extensionElements.childNodes || []).forEach((child) => {
                const name = child.getAttribute && child.getAttribute('name');
                if(name === 'scriptId') {
                    extensionElements.removeChild(child);
                }
            });
        }

        // removing child if it's script field
        if (script) {
            parentElement.removeChild(script);
        }

        if(!value.script) {
            return prettifyXml(String(doc));
        }

        // populatin value
        if (value.type === 'bpmn') {
            parentElement.removeAttribute('flowable:delegateExpression'); // eslint-disable-line no-template-curly-in-string
            parentElement.setAttribute('scriptFormat', 'javascript');
            const scriptElmntString = `<script><![CDATA[${value.script}]]></script>`;
            const newScriptElmnt = new DOMParser().parseFromString(scriptElmntString);
            parentElement.appendChild(newScriptElmnt);
        } else {
            parentElement.setAttribute('flowable:delegateExpression', '${gqlScriptServiceTask}'); // eslint-disable-line no-template-curly-in-string

            if(!extensionElements) {
                extensionElements = parseDomElement(`<extensionElements></<extensionElements>`, 'extensionElements');

                const incomingElmnt =  parentElement.getElementsByTagName('incoming')[0];
                if(incomingElmnt) {
                    parentElement.insertBefore(extensionElements, incomingElmnt);
                } else {
                    parentElement.appendChild(extensionElements);
                }

                doc = new DOMParser().parseFromString(String(doc));
                parentElement =  doc.getElementById(elementId);
                Array.from(parentElement.childNodes).forEach((child) => {
                    if(child.localName === 'extensionElements') {
                        extensionElements = child;
                    }
                });
            }

            const scriptField = new DOMParser().parseFromString(`
                <flowable:field name="scriptId">
                    <flowable:string>
                        <![CDATA[${value.script}]]>
                    </flowable:string>
                </flowable:field>
            `);

            extensionElements.appendChild(scriptField);
        }

        return prettifyXml(String(doc));
    }
    @bind
    updateDocumentationProps(data, { name, value }) {
        const { process } = this.props;
        const { selectedModelElement } = this.state;
        let doc = new DOMParser().parseFromString(process.primary.definition);
        let parentElement =  doc.getElementById(selectedModelElement.id);

        let extensionElements = null;
        Array.from(parentElement.childNodes).forEach((child) => {
            if(child.localName === 'extensionElements') {
                extensionElements = child;
            }
        });

        // removing child if it's nodejs field
        if(extensionElements) {
            Array.from(extensionElements.childNodes || []).forEach((child) => {
                const name = child.getAttribute && child.getAttribute('name');
                if(name === 'documentation') {
                    extensionElements.removeChild(child);
                }
            });
        }

        if(!extensionElements) {
            extensionElements = new DOMParser().parseFromString(`<extensionElements></<extensionElements>`);
            extensionElements = extensionElements.getElementsByTagName('extensionElements')[0];

            const incomingElmnt =  parentElement.getElementsByTagName('incoming')[0];
            const outgoingElmnt =  parentElement.getElementsByTagName('outgoing')[0];
            if(incomingElmnt || outgoingElmnt) {
                parentElement.insertBefore(extensionElements, incomingElmnt || outgoingElmnt);
            } else {
                parentElement.appendChild(extensionElements);
            }

            doc = new DOMParser().parseFromString(String(doc));
            parentElement =  doc.getElementById(selectedModelElement.id);
            Array.from(parentElement.childNodes).forEach((child) => {
                if(child.localName === 'extensionElements') {
                    extensionElements = child;
                }
            });
        }

        const documentationField = new DOMParser().parseFromString(`
                <flowable:field name="documentation">
                    <flowable:string>
                        <![CDATA[${value}]]>
                    </flowable:string>
                </flowable:field>
            `);

        extensionElements.appendChild(documentationField);
        

        return prettifyXml(String(doc));
    }

    @bind
    updateSequenceFlow(data, { name, value }) {
        const { process } = this.props;
        const { selectedModelElement } = this.state;
        const doc = new DOMParser().parseFromString(process.primary.definition);
        const parentElement =  doc.getElementById(selectedModelElement.id);

        let conditionExpression = parentElement.getElementsByTagName('conditionExpression')[0];
        if(conditionExpression) {
            parentElement.removeChild(conditionExpression);
        }
        if(value?.flowCondition) {
            conditionExpression = new DOMParser().parseFromString(`
              <conditionExpression xsi:type="tFormalExpression"><![CDATA[${value.flowCondition}]]></conditionExpression>
          `);
            parentElement.appendChild(conditionExpression);
        }

        return prettifyXml(String(doc));
    }

    @bind
    updateEventProps(data, { name, value }) {
        const { process } = this.props;
        const { selectedModelElement, selectedElement } = this.state;

        const doc = new DOMParser().parseFromString(removeSpaces(process.primary.definition));
        const parenElement =  doc.getElementById(selectedModelElement.id);
        const tagName = buildEventType(selectedModelElement.type, selectedElement);
        const eventTimeElmnt = parenElement.getElementsByTagName(tagName)[0];
        if (eventTimeElmnt) {
            parenElement.removeChild(eventTimeElmnt);
        }

        const timeWrapperElmnt = new DOMParser().parseFromString(`<${tagName}><${value['type']}>${value.date}</${value['type']}></${tagName}>`);
        parenElement.appendChild(timeWrapperElmnt);

        return prettifyXml(String(doc));
    }

    @bind
    importXml(xml) {
        return new Promise(async (resolve) => {
            const canvas = this.modeler.get('canvas');
            const viewbox = { ...canvas.viewbox() };
            const size = { ...canvas.getSize() };

            const { warnings } = await this.modeler.importXML(xml);

            canvas.viewbox({
                ...size,
                x: viewbox.x,
                y: viewbox.y,
            });

            this.setState({ warnings }, () => {resolve();});
        });
    }

    @bind
    @debounce(100)
    async updateElementSettings(data, { name, value }){
        const { selectedModelElement } = this.state;
        const { process } = this.props;

        this.checkErrors(selectedModelElement.id);
        
        if (name === 'process.primary.definition') {
            await this.importXml(value);
            return this.props.saveEditorState({ primary: { definition: value }});
        }

        if (name.startsWith('process.')) {
            return this.props.saveEditorState({ ...data.process });
        }
        
        if (name.startsWith('multiinstance.')) {
            const xml = await this.updateMultiinstanceProps(data, { name, value });
            await this.importXml(xml);
            return this.props.saveEditorState({ primary: { definition: xml }});
        }

        let definition = process.primary.definition;

        if (name === 'documentation') {
            definition = await this.updateDocumentationProps(data, { name, value });
            await this.importXml(definition);
            return this.props.saveEditorState({ primary: { definition }});
        }

        if(selectedModelElement.type === 'bpmn:Group' && name === 'name') {
            definition = await this.updateGroupName(data, { name, value });
            await this.importXml(definition);
            return this.props.saveEditorState({ primary: { definition }});
        }

        if(selectedModelElement.type === 'bpmn:TextAnnotation' && name === 'name') {
            definition = await this.updateTextAnnotationName(data, { name, value });
            await this.importXml(definition);
            return this.props.saveEditorState({ primary: { definition }});
        }

        switch(name) {
            case 'elementId': {
                selectedModelElement.businessObject.set('id', value.id);
                const { xml } = await this.modeler.saveXML();

                return this.setState({ selectedElement: null, selectedModelElement: null  }, async () => {
                    await this.importXml(xml);
                    await this.props.saveEditorState({ primary: { definition: xml }});
                    await this.openElementSidebar();
                });
            }
            case 'script': {
                definition = this.updateScriptProps(data, { name, value });
                this.importXml(definition);
                break;
            }
            case 'flowCondition': {
                definition = this.updateSequenceFlow(data, { name, value });
                this.importXml(definition);
                break;
            }
            case 'dateTimerEvent': {
                definition = this.updateEventProps(data, { name, value });
                await this.importXml(definition);
                break;
            }
            case 'processName': {
                const doc = new DOMParser().parseFromString(process.primary.definition);
                const processElmnt =  doc.getElementsByTagName('process')[0] || doc.getElementsByTagName('bpmn:process')[0];
                processElmnt.setAttribute('name', value || '');
                definition = String(doc);
                await this.importXml(definition);
                this.props.saveEditorState({ ...process, primary: { definition }, name: value || '' });
                break;
            }
            default: {
                const doc = new DOMParser().parseFromString(process.primary.definition);
                const element =  doc.getElementById(selectedModelElement.id);
                element.setAttribute(name, value || '');
                definition = String(doc);
                await this.importXml(definition);
            }
        }
        const doc = new DOMParser().parseFromString(definition);
        const selectedElementNew =  doc.getElementById(selectedModelElement.id);
        
        this.setState({ selectedElement: selectedElementNew }, async () => {
            await this.props.saveEditorState({ primary: { definition }});
            this.openElementSidebar();
        });
    }

    @bind
    async onChangeDiagram(e) {
        const { xml } = await this.modeler.saveXML();
        this.props.saveEditorState({ primary: { definition: xml }});
    };

    @bind
    openAboutTab() {
        var eventBus = this.modeler.get('eventBus');
        const canvas = this.modeler.get('canvas');
        const rootElement = canvas.getRootElement();
        eventBus.fire('element.click', { element: rootElement });
    }

    @bind
    openElementSidebar(fromView = false) {
        const { process, buildSidebar } = this.props;
        const { selectedElement, selectedModelElement } = this.state;

        if(selectedElement?.tagName === 'process' && fromView) {
            return this.setState({ selectedElement: null, selectedModelElement: null }, this.openElementSidebar);
        }

        const sidebarProps = this.getPropertiesSidebarProps(selectedElement, selectedModelElement, process);
        buildSidebar({
            isOpen: true,
            onClose: this.handleCloseSidebar,
            ...sidebarProps,
        });
    }

    @bind
    @debounce(500)
    async checkErrors(elementId) {    
        if(this.propertiesRef.current) {
            const { errors } = await this.propertiesRef.current.isValidForm();
            this.props.errorsState(errors, elementId);    
        }
    }

    @bind
    @debounce(400)
    async onElementClick(event) {
        const { isPreviewVersion } = this.props;
        if(isPreviewVersion) return;

        const { newSelection, element: evntElement } = event;
        let [ element ] = newSelection || [];
        if(!element && evntElement) {
            element = evntElement;
        }
        if(element) {
            const { process } = this.props;
            const definition = get(process, 'primary.definition', '');
            if(!definition) {
                return;
            }

            this.checkErrors(element.id);

            const doc = new DOMParser().parseFromString(definition);
            let selectedElement =  doc.getElementById(element.id);
            if(element.type === 'bpmn:Collaboration') {
                const rootSvg =  doc.getElementsByTagName('process')[0] || doc.getElementsByTagName('bpmn:process')[0];
                selectedElement =  rootSvg;
            }
            this.setState({ selectedElement, selectedModelElement: element }, () => this.openElementSidebar());
        }
    };

    @bind
    async onElementChanged(event) {
        const { element } = event;
        if (get(element, 'id') === get(this.state.selectedModelElement , 'id')) {
            const { xml: definition } = await this.modeler.saveXML();
            const doc = new DOMParser().parseFromString(definition);
            const selectedElement =  doc.getElementById(element.id);
            this.setState({ selectedElement, selectedModelElement: element });
        }
    };
    @bind
    @debounce(500)
    async onElementRemoved(event) {
        const { process, errorsState } = this.props;

        const doc = new DOMParser().parseFromString(process.primary.definition);
        const element =  doc.getElementById(event.element.id);

        if(!element && event.element.type !== 'label') {
            errorsState(null, event.element.id);
            this.setState({ selectedElement: null, selectedModelElement: null }, this.openElementSidebar);
        }
    };

    @bind
    @memoize()
    buildWarnings(warnings) {
        return warnings.map((warn, ind) => (
            <Alert type="error" key={ind}>{String(warn.message)}</Alert>
        ));
    }

    @bind
    @memoize()
    getPropertiesSidebarProps(selectedElement, selectedModelElement, process) {
        const created = isElementCreated(selectedElement, process);
        if(!selectedElement || !created) {
            return { 
                title: 'Properties', 
                afterCloseSidebar: this.handleCloseSidebar,
                content: (
                    <GridStyled justify="center" alignItems="center" container>
                        <Typography variant="h5">Select element from diagram.</Typography>
                    </GridStyled>
                )
            };
        }
        let type = selectedModelElement.type;
        if(type.endsWith('Event')) {
            type = buildEventType(type, selectedElement);
        }
        const components = getElementSettingPanelComponents(type);
        const values = getElementSettingValues(selectedElement, type, process);

        // normalize properties of formDefinitionVersionTypeahead
        components[0].children.forEach(comp => {
            if(comp.type === 'formDefinitionVersionTypeahead') {
                comp.properties.formDefinitionId = values['affectli:formId'] || '';
            }
        });

        return {
            isOpen: !!selectedElement && !!selectedModelElement,
            title: selectedElement?.tagName === 'process' ? 'Process Properties' : 'Properties',
            afterCloseSidebar: this.handleCloseSidebar,
            /*
             * cid: it's for caching of changes if id property was changed in the same element
             */
            content: (
                <FormGenerator
                    key={type}
                    ref={this.propertiesRef}
                    onChange={this.updateElementSettings}
                    components={components}
                    data={{
                        ...values,
                        cid: values.id,
                        process, type,
                        selectedIcon:  values.selectedIcon || process.iconName ? { value: process.iconName, label: process.iconName, type: process.iconType || 'mdi' } : null,
                        processName: process.name,
                    }}
                />
            ),
        };
    };

    render() {
        const { actions, tabs, secondaryActions, breadcrumbLine, afterRightButton, draftedLabel } = this.props;
        const { warnings } = this.state;
        return (
            <Fragment>
                <Layout
                    tabs={tabs}
                    TabsToolbarContent={secondaryActions}
                    ToolbarContent={<>
                        {actions}
                        <Tooltip title="About">
                            <IconButton onClick={this.openAboutTab}><Icon hexColor={muiTheme.colors.text.secondary} name="information-outline" /></IconButton>
                        </Tooltip>
                        <Tooltip title="Properties">
                            <IconButton onClick={() => this.openElementSidebar(true)}><Icon hexColor={muiTheme.colors.text.secondary} name="classification-editor" type="af" /></IconButton>
                        </Tooltip>
                    </>}
                    leftToolbarContent={<>
                        {breadcrumbLine}
                        {draftedLabel}
                    </>}
                    disableRightMenu
                    afterRightButton={afterRightButton}
                    content={
                        <>
                            <DiagramContainer key="diagramcontainer">
                                {this.buildWarnings(warnings)}
                                <div className="content with-diagram" id="js-drop-zone">
                                    <DiagramWrapper>
                                        <div className="canvas" id="js-canvas" />
                                    </DiagramWrapper>
                                </div>
                            </DiagramContainer>
                        </>
                    }
                    height={'calc(100vh - 145px)'}
                />
            </Fragment>
        );
    }
}

export default connect(state => ({
    isLoading: state.designer.process.isLoading,
}), { setTabActions, buildSidebar, closeSidebar }, null, { forwardRef: true })(ProcessDesignerDiagram);
