/* @flow */
import { buildIndexAttributeDefinition } from 'app/components/molecules/IndexAttribute/IndexAttribute';
import { name, label, placeholder } from 'app/utils/designer/form/settings/common/commonBase';
import { isDefined } from 'app/utils/utils';

import { onLoad, onChange, isVisible, isDisabled, onCalculation } from 'app/utils/designer/form/settings/common/commonEvents';
import { help } from 'app/utils/designer/form/settings/common/commonHelpers';
import { required, classRequired, minLength, maxLength, readOnly, staticAttribute } from 'app/utils/designer/form/settings/common/commonValidation';
import { flexGrow } from 'app/utils/designer/form/settings/common/commonStyles';
import { set } from 'app/utils/immutable/Immutable';
import { get } from 'app/utils/lo/lo';


const minMessage = 'min value cannot be greater than or equal to max value';
const maxMessage = 'max value cannot be less than or equal to min value';

const customValidation = (message, minField, maxField)  => (data) => {
    const min = get(data, minField);
    const max = get(data, maxField);
    if(isDefined(min) && isDefined(max) && min >=  max) {
        return ({ format: { pattern: /somefaketest/, message }});
    }
    return {};
};

export const validateMinMax = (minField, maxField, minFieldName, maxFieldName)=> {
    let min = {...minField};
    let max = {...maxField};
    min = set(min, 'constraints.custom', customValidation(minMessage, minFieldName, maxFieldName));
    max = set(max, 'constraints.custom', customValidation(maxMessage, minFieldName, maxFieldName));
    return [min, max];
};

const _getMinMaxConstraints = (settingsValues) => validateMinMax(minLength, maxLength, 'constraints.minLength', 'constraints.maxLength');


const panels = settingsValues => [
    {
        header: 'Base',
        children: [
            name,
            label,
            { 
                type: 'typeahead',
                properties: {
                    name: 'properties.extraType',
                    label: 'Field type',
                    valueField: 'value',
                    options: [{ value: 'text', label: 'Simple text' }, { value: 'password', label: 'Masked text' }]
                }
            },
            placeholder,
        ],
    },
    settingsValues?.viewType === 'class' && onCalculation,    
    {
        header: 'Events',
        children: [
            onLoad,
            onChange,
            isVisible,
            settingsValues?.viewType !== 'class' && isDisabled
        ].filter(Boolean)
    },
    {
        header: 'Validation',
        children: [
            settingsValues?.viewType !== 'class' ? required : classRequired,
            settingsValues?.viewType === 'class' && readOnly,
            settingsValues?.viewType === 'class' && staticAttribute(settingsValues),
            ..._getMinMaxConstraints(settingsValues)
        ].filter(Boolean)
    },
    buildIndexAttributeDefinition(settingsValues),
    {
        header: 'Styles',
        children: [ flexGrow ]
    },
    {
        header: 'Helpers',
        children: [ help ]
    },
].filter(Boolean);

export default {
    defaults: { withDefault: true, extraType: 'text' },
    panels: settingValues => panels(settingValues),
};

