import React from 'react';
import uuidv1 from 'uuid/v1';

import ModalFormGenearator from 'app/components/organisms/ModalFormGenearator/ModalFormGenearator';

export const id = (label) => ({
    type: 'custom',
    properties: {
        cid: uuidv1(),
        title: label || 'ID',
        disabled: (data) => !['bpmn:StartEvent', 'bpmn:UserTask'].includes(data.type),
        name: 'elementId',
        Component: props => (
            <ModalFormGenearator
                {...props}
                required
                previewRender={value => value && value.id}
                components={[
                    {
                        type: 'text',
                        properties: {
                            label: label || 'ID',
                            name: 'id',
                        },
                        constraints: { 
                            required: true,
                            minLength: 3,
                            custom: (data) => {
                                const noStartFromNumbers = { format: {
                                    pattern: /somefaketest/,
                                    message: 'Only the following characters are allowed: Letters, Numbers, Dashes, Underscores'
                                }};
                                if (!/^[a-zA-Z\-_][0-9a-zA-Z\-_]*$/g.test(data.id)) {
                                    return noStartFromNumbers;
                                }
                                return {};
                            }}
                    }
                ]}
            />
        ),
    },
    constraints: { required: true }
});

export const name = {
    type: 'text',
    properties: {
        label: 'Name',
        name: 'name',
    },
};

export const formReference = {
    type: 'formDefinitionTypeahead',
    properties: {
        label: 'Form reference',
        name: 'affectli:formId',
        valueField: 'id',
        filterBy: [{ field: 'primary.lastVersion', op: 'is not null' }],
    },
    constraints: { required: true }
};

export const formVersionReference = {
    type: 'formDefinitionVersionTypeahead',
    properties: {
        label: 'Form reference version',
        name: 'affectli:formVersion',
        valueField: 'value'
    },
};

export const documentationPanel = {
    header: 'Documentation',
    children: [            {
        type: 'textEditor',
        properties: {
            label: 'Documentation',
            name: 'documentation',
        }
    },]
};