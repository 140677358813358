/* @flow */

import React, { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, Button } from '@mic3/platform-ui';
import styled from 'styled-components';

import { useToggle } from 'app/utils/hook/hooks';
import ModalDialog from 'app/components/organisms/ModalDialog/ModalDialog';
import FormGenerator from 'app/containers/Designer/Form/components/FormGenerator';

const GridStyled = styled(Grid)`
    padding: 0 12px;
    cursor: pointer;
    width: 100%;
`;

const TypographyStyled = styled(Typography)`
  color: ${({ disabled, theme }) => disabled ? theme.material.colors.disabled.main : theme.material.colors.text.caption} !important;
`;
const TypographyContentStyled = styled(Typography)`
  color: ${({ disabled, theme }) => disabled ? theme.material.colors.disabled.main : theme.material.colors.text.secondary} !important;
`;

const ModalFormGenearator = (props: Object) => {
    const { 
        withoutPreview, onClose, components, value, labelSubmitButton,
        previewRender, name, title, onChange, disabled, required, isOpen: isOpenDefault 
    } = props;
    const [isOpen, toggle] = useToggle(isOpenDefault);
    const formRef = useRef(null);

    const [disabledApply, setDisabledApply] = useState(false);
    
    const handleClose = useCallback((e) => {
        if(disabled) return;
        if(onClose) onClose();
        else toggle();
    }, [onClose, toggle, disabled]);

    const onFormSubmit = useCallback((e) => {
        formRef.current.isValidForm().then(({ data, errors }) => {
            if (!errors) {
                onChange({ target: { value: data, name }});
                handleClose();
            }
        });
    }, [name, onChange, handleClose]);

    const preview = previewRender ? previewRender(value) : value;

    return (
        <>
            {!withoutPreview && (
                <GridStyled onClick={handleClose} container direction="column" alignItems="stretch">
                    <TypographyStyled disabled={disabled} variant="caption">{title} {required ? (<span style={{ color: 'red'}}>*</span>) : ''}</TypographyStyled>
                    <TypographyContentStyled disabled={disabled}>{preview}</TypographyContentStyled>
                </GridStyled>
            )}
            {isOpen && (
                <ModalDialog
                    title={title}
                    onClose={handleClose}
                    actions={
                        <Button variant="text" onClick={onFormSubmit} disabled={disabledApply}>
                            {labelSubmitButton || 'Apply'}
                        </Button>
                    }
                >
                    <FormGenerator
                        onStartChanges={() => setDisabledApply(true)}
                        onEndChanges={async () => {
                            const { errors } = await formRef.current.isValidForm();
                            if(!errors) setDisabledApply(false);
                        }}
                        components={components}
                        ref={formRef}
                        data={value}
                    />
                </ModalDialog>
            )}
        </>
    );
};

ModalFormGenearator.propTypes = {
    // ...(FormGenerator || {}).propTypes,
    title: PropTypes.string.isRequired,
    previewRender: PropTypes.func,
    name: PropTypes.string,
};

export default ModalFormGenearator;
