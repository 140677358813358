/* @flow */

import { gql } from '@apollo/client';
import { digitalTwinShortQueryFields } from 'graphql/entities/classifications/classificationDetailQuery';
import { entityReferences } from 'graphql/entities/relationships/relationsQuery';

export const tasksFields = `
    id
    type
    name
    primary(fields: ["assignee", "owner", "priority", "dueDate", "progress", "closedDate", "startDate", "sequenceNumber"])
`;

export default gql`
query tasksEntityQuery($startIndex: Int, $stopIndex: Int, $filterBy: [JSON], $orderBy: [JSON], $excludeBy: [JSON]) {
    count: count(dataType: "task", filterBy: $filterBy, excludeBy: $excludeBy)
    records: tasks(startIndex: $startIndex, stopIndex: $stopIndex, filterBy: $filterBy, orderBy: $orderBy, excludeBy: $excludeBy) {
        ${tasksFields}
        primaryClass {
            ${digitalTwinShortQueryFields}
        }
        process {
          variables(fields:["INITIATOR"])
        }
        classes {
            id
            name
        }
        relations {
          relation {
              id
              relationDefinition {
                  id
              }
          }
          relatedEntity {
            ${entityReferences}
          }
        }
        workspaces {
            isSystem
        }
        createdDate
        modifiedDate
        assignee {
            id
            name
            image
        }
        createdBy {
            id
            name
            image
        }
        modifiedBy {
            id
            name
            image
        }
    }
}
`;
