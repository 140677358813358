/* @flow */

import React from 'react';
import uuidv1 from 'uuid/v1';

import { id, name, documentationPanel } from 'app/utils/designer/process/settings/common/commonImportant';
import { executionListener } from 'app/utils/designer/process/settings/common/commonListeners';
import commonMultiinstance from 'app/utils/designer/process/settings/common/commonMultiinstance';
import ModalFormGenearator from 'app/components/organisms/ModalFormGenearator/ModalFormGenearator';

const panels = [
    {
        header: 'Important',
        expanded: true,
        children: [
            id(),
            name,
            {
                type: 'custom',
                properties: {
                    cid: uuidv1(),
                    title: 'Script',
                    name: 'script',
                    Component: props => (
                        <ModalFormGenearator
                            {...props}
                            required
                            previewRender={value => value && value.script && value.script.slice(0,30)}
                            components={[
                                {
                                    type: 'typeahead',
                                    properties: {
                                        label: 'Type',
                                        options: [
                                            { label: 'BPMN Script', value: 'bpmn'},
                                            { label: 'Node.js Script', value: 'nodeJs' },
                                        ],
                                        name: 'type',
                                        defaultValue: 'bpmn',
                                        clearable: false,
                                        onChange: ({ target }) => {
                                            if(target.value === 'bpmn') {
                                                return [target, { name: 'script', value: '// TODO implement the script' }];
                                            } else {
                                                return [{ name: 'script', value: null }, target];
                                            }
                                        }
                                    },
                                    constraints: { required: true }
                                },
                                {
                                    type: 'scriptTypeahead',
                                    properties: {
                                        label: 'Script reference',
                                        name: 'script',
                                        valueField: 'id',
                                        isVisible: data => data.type === 'nodeJs'
                                    },
                                    constraints: { required: true }
                                },
                                {
                                    type: 'textarea',
                                    properties: {
                                        modal: true,
                                        useCodeEditor: true,
                                        parseAs: 'javascript',
                                        title: 'Script Bpmn',
                                        name: 'script',
                                        fullJs: true,
                                        noValidation: true,
                                        isVisible: data => data.type === 'bpmn'
                                    },
                                    constraints: { required: true }
                                },
                            ]}
                        />
                    ),
                },
                constraints: { required: true }
            },
            {
                type: 'typeahead',
                properties: {
                    label: 'Asynch/synch',
                    options: [{ label: 'Asynchronous', value: 'true'}, { label: 'Async/sync', value: 'false' }],
                    name: 'activiti:async',
                    defaultValue: 'false',
                    clearable: false,
                }
            },
        ],
    },
    documentationPanel,
    {
        header: 'Listeners',
        children: [
            executionListener,
        ]
    },
    commonMultiinstance,
];

export default {
    defaults: {},
    panels: settingValues => panels,
};
