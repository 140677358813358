/* @flow */

import { id, name, documentationPanel } from 'app/utils/designer/process/settings/common/commonImportant';
import { executionListener } from 'app/utils/designer/process/settings/common/commonListeners';

const panels = [
    {
        header: 'Important',
        expanded: true,
        children: [
            id(),
            name,
        ],
    },
    documentationPanel,
    {
        header: 'Listeners',
        children: [executionListener]
    },
];

export default {
    defaults: {},
    panels: settingValues => panels,
};
